var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-tag",class:{
    'info-tag-warning': _vm.type === 'warning',
    'info-tag-success': _vm.type === 'success',
    'info-tag-info': _vm.type === 'info',
    'info-tag-some': _vm.type === 'some',
    'info-tag-failure': _vm.type === 'failure',
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }