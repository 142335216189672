var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sof-box relative"},[_c('div',{staticClass:"sof-box-preview"},[_c(_vm.sectionsComponent,{tag:"component"})],1),_c('div',{staticClass:"sof-box-main space-between gap-30 flex-items-center flex-1 relative",class:{
      'sof-box-hover': _vm.showHoverOnCard,
      'sof-box-hover-full-details': _vm.hoverAllPackage,
    }},[_c('div',{staticClass:"max-w-280"},[_c('div',{staticClass:"sof-box-cover",class:{
          'sof-box-hover': _vm.showHoverOnCard,
          'sof-box-hover-full-details': _vm.hoverAllPackage,
        }}),_c('div',{staticClass:"sof-box-head-text mb-2 t-mb-3"},[_vm._v(" "+_vm._s(_vm.cartData.title)+" ")]),_c('div',{staticClass:"sof-box-text t-mb-3"},[_vm._v(" "+_vm._s(_vm.cartData.description)+" ")])]),_c('div',{staticClass:"flex column justify-center"},[_c('transition-group',{attrs:{"name":"fade-leave"}},[(_vm.cartData.fakeLoadingPreview >= 0)?_c('div',{key:_vm.order + 'loader'},[_c('gl-loader',{attrs:{"spin-dot":"","width":"70px"}})],1):_c('div',{key:_vm.order + 'tag'},[_c('GlInfoTag',{staticClass:"bold-600 max-w-200",attrs:{"type":_vm.typeOfTag}},[_c('gl-icon',{staticClass:"mr-2",attrs:{"failure":_vm.typeOfTag === 'failure',"height":20,"name":_vm.typeOfTag === 'failure' ? 'failure' : 'done',"some":_vm.typeOfTag === 'some',"success":_vm.typeOfTag === 'success',"warning":_vm.typeOfTag === 'warning',"width":20}}),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.cartData.status)+" ")])],1)],1)])],1),_c('div',[(_vm.cartData.reportPartName === 'owner')?_c('div',{staticClass:"bold text-center min-w-250"},[_vm._v(" Included in next reports ")]):_c('GlButtonPrime',{staticClass:"sof-box-button min-w-250 height-50 bold-600 fs-16",class:{
          'sof-box-button-hover p-button-success': _vm.showHoverOnButton,
          'p-button-warning': _vm.cartData.reportPartName == 'full',
        },attrs:{"disabled":_vm.typeOfTag === 'failure',"loading":_vm.cartData.loading},on:{"click":function($event){return _vm.$emit('button-click', _vm.cartData)},"mouseleave":function($event){return _vm.onMouseleaverButton(_vm.order)},"mouseover":function($event){return _vm.onMouseoverButton(_vm.order)}}},[_c('transition-group',{attrs:{"name":"slide"}},[(_vm.showHoverOnButton)?_c('div',{key:_vm.order + 'Included',staticClass:"flex justify-center"},[_c('gl-icon',{staticClass:"mr-2",attrs:{"height":20,"name":"done","white":"","width":20}}),_vm._v(" Included ")],1):_c('div',{key:_vm.order + 'thunder',staticClass:"flex justify-center"},[_vm._v(" "+_vm._s(_vm.cartData.purchased ? 'View' : `Unlock for ${_vm.cartData.price || ''}`)+" "),(!_vm.cartData.purchased)?_c('gl-icon',{staticClass:"ml-1 mr-2",attrs:{"height":20,"name":"lookups-white","width":20}}):_vm._e()],1)])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }